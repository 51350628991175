@font-face {
  font-family: "Cal Sans";
  src: url("/assets/mvine/fonts/CalSans.woff2") format("woff2");
}
:root {
  --mvine-header-height: 42px;
  --mvine-expanded-height: 84vh;
  --mvine-content-height: calc(var(--mvine-expanded-height) - var(--mvine-header-height));
  --mvine-content-width: 400px;
}

.cal-sans {
  font-family: "Cal Sans", "Inter", sans-serif;
}

.mvine-chat {
  position: fixed;
  width: var(--mvine-content-width);
  background-color: var(--bg-color);
  box-shadow: var(--shadow-md);
  border-top: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
  overflow-x: hidden;
  z-index: 1000;
  bottom: 0;
  border-top-left-radius: var(--border-radius-md);
  border-top-right-radius: var(--border-radius-md);
  right: 15px;
}

.skeleton {
  background-color: var(--skeleton-bg);
  animation: 2s breathe infinite;
}

@keyframes breathe {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
.break-all {
  word-break: break-all;
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.mvine-container {
  transition: height 0.3s ease-in-out;
}

.mvine-container[data-open-state=false] {
  height: var(--mvine-header-height);
  overflow: hidden;
}

.mvine-container[data-open-state=true] {
  height: var(--mvine-expanded-height);
  overflow: hidden;
}
.mvine-container[data-open-state=true] .mvine-header {
  border-bottom: 1px solid var(--border-color);
}

.mvine-content-container {
  position: relative;
}

.mvine-content-container[data-channel-list-view=false] .mvine-channel-list {
  width: 0;
  position: absolute;
  right: -100%;
  opacity: 0;
}
.mvine-content-container[data-channel-list-view=false] .mvine-chat-view {
  width: 100%;
  position: absolute;
  right: 0;
  opacity: 1;
}

.mvine-content-container[data-channel-list-view=true] .mvine-channel-list {
  width: 100%;
  opacity: 1;
  position: absolute;
  right: 0;
}
.mvine-content-container[data-channel-list-view=true] .mvine-chat-view {
  width: 0;
  right: 100%;
  position: absolute;
  opacity: 0;
}

.mvine-header {
  height: var(--mvine-header-height);
  padding-inline: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: 0.5rem;
}
.mvine-header .open-mvine-button {
  padding: 4px;
  box-shadow: none;
  border: none;
}
.mvine-header .open-mvine-button svg {
  fill: var(--text-muted);
}
.mvine-header .open-mvine-button:hover {
  background-color: var(--control-bg);
}
.mvine-header .back-button {
  padding: 4px;
}
.mvine-header .back-button svg {
  fill: var(--heading-color);
}
.mvine-header .back-button:hover {
  background-color: var(--control-bg);
}

.mvine-logo {
  font-weight: 700;
  font-size: 1.4rem;
  color: var(--heading-color);
  padding-left: 4px;
}

.mvine-unread-count {
  background-color: var(--red-avatar-bg);
  color: var(--red-avatar-color);
  padding: 0.1rem 0.5rem;
  margin-bottom: -0.3rem;
  margin-left: 4px;
  border-radius: var(--border-radius-sm);
  font-size: 0.7rem;
  font-weight: 700;
}

.mvine-channel-header-name {
  font-weight: 700;
  font-size: 1rem;
}

.mvine-channel-header {
  display: flex;
  align-items: center;
  color: var(--heading-color);
  gap: 0.4rem;
}
.mvine-channel-header svg {
  fill: var(--heading-color);
}
.mvine-channel-header a {
  display: flex;
  align-items: center;
  gap: 0.1rem;
}
.mvine-channel-header .mvine-dm-channel-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.mvine-channel-list {
  overflow-y: auto;
  height: var(--mvine-content-height);
  transition: all 0.3s ease-in-out;
}
.mvine-channel-list h4 {
  font-size: 0.6rem;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--text-muted);
  padding: 0.5rem 0.4rem;
  margin: 0;
}

.mvine-channel-list-item {
  padding: 0.3rem 0.5rem;
  display: flex;
  width: 100%;
  background-color: transparent;
  gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
  border: none;
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;
}
.mvine-channel-list-item .channel-label {
  gap: 0.5rem;
  display: flex;
  align-items: center;
  font-family: "Cal Sans", "Inter", sans-serif;
}
.mvine-channel-list-item:hover {
  background-color: var(--bg-light-gray);
}
.mvine-channel-list-item .skeleton {
  height: 1.6rem;
  display: block;
  width: 100%;
  border-radius: var(--border-radius-md);
}
.mvine-channel-list-item .mvine-channel-icon {
  height: 1.8rem;
  display: flex;
  fill: var(--heading-color);
  align-items: center;
  justify-content: center;
  width: 1.8rem;
}
.mvine-channel-list-item .channel-unread-count {
  background-color: var(--red-avatar-bg);
  color: var(--red-avatar-color);
  padding: 0.1rem 0.5rem;
  margin-bottom: -0.3rem;
  margin-left: 4px;
  border-radius: var(--border-radius-sm);
  font-size: 0.7rem;
  font-weight: 700;
}

.mvine-avatar {
  width: 1.8rem;
  height: 1.8rem;
  min-width: 1.8rem;
  min-height: 1.8rem;
  display: block;
  border-radius: 0.5rem;
}
.mvine-avatar .placeholder {
  display: flex;
  align-items: center;
  height: 1.8rem;
  width: 1.8rem;
  justify-content: center;
}

.mvine-chat-view {
  transition: all 0.3s ease-in-out;
  overflow-x: hidden;
  width: var(--mvine-content-width);
  -webkit-clip-path: inset(0 0 0 0);
          clip-path: inset(0 0 0 0);
}
.mvine-chat-view .mvine-chat-view-container {
  position: relative;
  width: var(--mvine-content-width);
  height: var(--mvine-content-height);
  overflow-x: hidden;
}
.mvine-chat-view .mvine-chat-view-container .mvine-chat-input {
  position: fixed;
  bottom: 0;
  display: flex;
  gap: 0.5rem;
  min-height: 60px;
  padding: 6px;
  border-top: 1px solid var(--border-color);
  width: var(--mvine-content-width);
}
.mvine-chat-view .mvine-chat-view-container .mvine-chat-input textarea {
  width: 100%;
  max-height: 60px;
  min-height: 60px;
  resize: none;
}
.mvine-chat-view .mvine-chat-view-container .mvine-chat-input .send-button svg {
  fill: var(--gray-8);
}
.mvine-chat-view .mvine-chat-view-container .mvine-chat-input .send-button:hover {
  background-color: var(--control-bg);
}
.mvine-chat-view .mvine-chat-view-container .mvine-message-stream-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
  height: calc(var(--mvine-content-height) - 68px);
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.mvine-date-separator {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}
.mvine-date-separator .mvine-date-separator-line {
  width: 100%;
  height: 1px;
  background-color: var(--border-color);
}
.mvine-date-separator .mvine-date-separator-text {
  font-size: 0.75rem;
  min-width: -moz-fit-content;
  min-width: fit-content;
  color: var(--text-color-secondary);
}

.message-item {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
}
.message-item .mvine-message-item-header .mvine-message-item-header-name {
  font-weight: 600;
  font-size: 0.8rem;
  color: var(--text-color);
}
.message-item .mvine-message-item-header .mvine-message-item-header-time {
  font-size: 0.7rem;
  padding-right: 0.3rem;
  margin-right: 0.3rem;
  border-right: 1px solid var(--border-color);
  color: var(--text-light);
}
.message-item .mvine-message-image-link {
  text-decoration: none;
  font-weight: 500;
  font-size: 0.85rem;
  color: var(--text-color);
  border-bottom: 1px solid var(--gray-400);
  padding-bottom: 0.05rem;
}
.message-item .mvine-message-image-link:hover {
  border-bottom: 1px solid var(--gray-600);
}

.mvine-tiptap-renderer {
  color: var(--text-color);
}
.mvine-tiptap-renderer a,
.mvine-tiptap-renderer .user-mention,
.mvine-tiptap-renderer .channel-mention {
  color: var(--blue-500);
}
.mvine-tiptap-renderer ol,
.mvine-tiptap-renderer ul {
  padding-right: 1rem;
}
.mvine-tiptap-renderer blockquote {
  border-right: 2px solid var(--gray-400);
  padding-right: 0.5rem;
  margin-right: 0.5rem;
}